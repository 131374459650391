<template>
  <div>
    <div class="section-header">
      Automated Imports
    </div>
    <div class="mt-8">
      <v-btn
        class="custom-button custom-button--blue"
        height="34px"
        depressed
        :block="$vuetify.breakpoint.xsOnly"
        @click="addImportConfiguration(null)"
      >
        Add Import Configuration
      </v-btn>
    </div>

    <div v-for="(form, index) in importConfigurations" :key="index" class="mt-4">
      <v-expansion-panels v-model="form.isShowing">
        <v-expansion-panel>
          <v-expansion-panel-header v-if="form.name">
            {{ form.name }}
          </v-expansion-panel-header>
          <v-expansion-panel-header v-else>
            Configuration #{{ index + 1 }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <AutomatedImportForm
              :form="form"
              :file-extension-options="fileExtensions"
              @clone-configuration="cloneImportConfiguration(index)"
              @remove-configuration="removeImportConfiguration(index)"
              @update-value="(key, value) => updateImportValue(index, key, value)"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <v-dialog v-model="deleteImportDialog" max-width="360px" @click:outside="deleteImportId = null">
      <DeleteConfirmationDialog
        v-if="deleteImportDialog"
        header="Delete configuration"
        subheader="Are you sure you want to delete this import configuration? All unsaved changes will be removed."
        @dismiss="
          deleteImportDialog = false;
          deleteImportId = null;
        "
        @delete="deleteImportConfig"
      />
    </v-dialog>

    <DeactivateConfirmDialog
      v-if="deactivateDialog"
      :id="id"
      :plugin="pluginData"
      @dismiss="deactivateDialog = false;"
    />
  </div>
</template>

<script>
import AutomatedImportForm from "@/views/Plugins/components/AutomatedImportForm";
import { v4 as uuidv4 } from "uuid";
import DeleteConfirmationDialog from "@/sharedComponents/DeleteConfirmationDialog";
import DeactivateConfirmDialog from "@/views/Plugins/components/DeactivateConfirmDialog";

export default {
  name: "AutomatedImportConfigurations",
  components: {
    AutomatedImportForm,
    DeleteConfirmationDialog,
    DeactivateConfirmDialog,
  },
  props: {
    id: {
      type: [Number, String],
      default: null,
      required: true,
    },
    pluginData: {
      type: Object,
      default: null,
    },
    importConfigurations: {
      type: Array,
      default: () => [],
      required: true,
    },
    fileExtensions: {
      type: Array,
      default: () => [],
    },
    importType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      deleteImportDialog: null,
      deleteImportId: null,
      deactivateDialog: false,
    };
  },
  methods: {
    addImportConfiguration(existingConfig) {
      this.$emit(
        'add-configuration',
        {
          connectionType: existingConfig?.connectionType || null,
          directory: existingConfig?.directory || '/',
          archiveDirectory: existingConfig?.archiveDirectory || '',
          fileExtension: existingConfig?.fileExtension || this.fileExtensions[0]?.id,
          credentials: {
            host: existingConfig?.credentials?.host || null,
            username: existingConfig?.credentials?.username || null,
            password: existingConfig?.credentials?.password || null,
            port: existingConfig?.credentials?.port || null,
            privateKey: existingConfig?.credentials?.privateKey || null,
            aws_region: existingConfig?.credentials?.aws_region || null,
            aws_access_id: existingConfig?.credentials?.aws_access_id || null,
            aws_secret_key: existingConfig?.credentials?.aws_secret_key || null,
            aws_bucket: existingConfig?.credentials?.aws_bucket || null,
          },
          frequency: existingConfig?.frequency || {
            type: null,
            ampm: (new Date()).getHours() >= 12 ? 'PM' : 'AM',
            hour: (new Date()).getHours(),
            minute: '00',
            timezone: 'America/New_York',
            date: [],
          },
          importType: this.importType,
          importOptions: existingConfig?.importOptions ? {...existingConfig?.importOptions} : { choice: null },
          name: existingConfig?.name || '',
          notificationRecipients: existingConfig?.notificationRecipients || '',
          key: existingConfig?.key || uuidv4(),
          isShowing: false,
          history: existingConfig?.history ? [...existingConfig?.history] : [],
          isExisting: !!existingConfig?.key,
        }
      );
    },
    cloneImportConfiguration(index) {
      const existingConfig = this.importConfigurations[index] ?? null;

      this.addImportConfiguration({
        ...existingConfig,
        name: 'Clone of ' + existingConfig?.name || '',
        key: null,
        history: null,
      });
    },
    removeImportConfiguration(index) {
      this.deleteImportDialog = true;
      this.deleteImportId = index;
    },
    async deleteImportConfig() {
      this.$emit('delete-configuration', this.deleteImportId);

      this.deleteImportDialog = null;
      this.deleteImportId = null;
    },
    updateImportValue(index, key, value) {
      this.$emit('update-configuration', index, key, value);
    },
  },
};
</script>

<style scoped>
.section-header {
  margin-top: 30px;
  font-size: 1.25rem;
  font-weight: bold;
}
</style>
