<template>
  <div class="custom-text-input">
    <div :class="headerClass || 'field__subheader d-flex'">
      {{ header }}
      <slot name="icon" />
    </div>
    <div v-if="subheader" class="field__subheader pt-2">
      {{ subheader }}
    </div>
    <validation-observer ref="textInput">
      <validation-provider
        v-slot="{ errors }"
        :rules="{
          required
        }"
        mode="eager"
      >
        <v-file-input
          class="file-input"
          :accept="accept"
          :label="placeholder || 'Select File'"
          :error-messages="errors"
          small-chips
          :hint="hint"
          :persistent-hint="!!hint"
          @change="onFileChange"
        />
      </validation-provider>
    </validation-observer>
  </div>
</template>

<script>
import { parseImageFileToBase64 } from "@/utils/helper";

export default {
  name: "CustomDropdown",
  props: {
    header: {
      default: () => {},
      type: String,
    },
    headerClass: {
      default: () => {},
      type: String,
    },
    subheader: {
      default: () => {},
      type: String,
    },
    placeholder: {
      default: () => {},
      type: String,
    },
    accept: {
      default: () => {},
      type: String,
    },
    value: {
      default: () => {},
    },
    required: {
      default: () => false,
      type: Boolean,
    },
    hint: {
      default: '',
      type: String,
    }
  },
  methods: {
    async onFileChange (file) {
      if (file && file.length) {
        file = file[0];
      }

      if (!(file instanceof File)) {
        this.$emit('onFileSelected', null);
        this.$emit('input', {});
        return;
      }

      // In case the parent component wants the file instead of a base64 encoded data structure
      this.$emit('onFileSelected', file);

      parseImageFileToBase64(file).then(imgLoaded => {
        this.$emit('input', {
          data: imgLoaded.data,
          fileName: imgLoaded.name,
        });
      }).catch(() => {
        this.$emit('input', {});
      });
    },
  }
};
</script>
