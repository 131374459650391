<template>
  <v-dialog v-model="deactivateDialog" scrollable max-width="360px">
    <ActionConfirmationDialog
      header="Deactivate Plugin"
      subheader="Do you really want to deactivate this plugin?"
      action-button-name="Confirm"
      @action="deactivePlugin()"
      @dismiss="$emit('dismiss')"
    />
  </v-dialog>
</template>

<script>
import ActionConfirmationDialog from "@/sharedComponents/ActionConfirmationDialog";

export default {
  name: "DeactivateConfirmDialog",
  components: {
    ActionConfirmationDialog,
  },
  props: {
    id: {
      type: [Number, String],
      default: () => null,
    },
    plugin: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      deactivateDialog: true,
    };
  },
  computed: {},
  created() {},
  methods: {
    async deactivePlugin() {
      const resp = await this.$rest.plugins.put_resource(this.id, {
        ...this.plugin,
        plugin: this.plugin.plugin.id,
        isActive: false,
      });
      if (resp.data) {
        const selectedAccount = this.$store.getters["user/account"];
        this.$store.commit("user/update_selected_account", {
          ...selectedAccount,
          activePlugins: Array.from(selectedAccount.activePlugins).filter(plugin => plugin.id !== resp.data.id),
        });
      }
      this.deactivateDialog = false;
      await this.$router.push({ name: 'Plugins' });
    }
  },
};
</script>

