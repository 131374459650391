<template>
  <div>
    <div class="section-header">
      Automated Exports
    </div>
    <div class="mt-8">
      <v-menu
        nudge-top="-5px"
        :offset-y="true"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            class="custom-button custom-button--blue"
            height="34px"
            depressed
            v-bind="attrs"
            v-on="on"
            @click="$emit('click')"
          >
            Add Export Configuration
          </v-btn>
        </template>
        <OuterList
          :items="exportConfigurationTypes"
          @click="$emit('add-configuration', $event.value)"
        />
      </v-menu>
    </div>

    <div 
      v-for="(form, index) in automatedExportConfigurations"
      :key="index"
      :class="getConfigurationClasses(form)"
    >
      <v-expansion-panels v-model="form.isShowing">
        <v-expansion-panel>
          <v-expansion-panel-header v-if="form.name">
            <div>
              <v-icon left>
                {{ form.type === 'single' ? 'mdi-file-document' : 'mdi-file-document-multiple' }}
              </v-icon>
              {{ form.name }}
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-header v-else>
            <div>
              <v-icon left>
                {{ form.type === 'single' ? 'mdi-file-document' : 'mdi-file-document-multiple' }}
              </v-icon>
              Configuration #{{ index + 1 }}
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <AutomatedExportForm
              :form="form"
              :export-type-options="exportTypeOptions"
              :contact-export-choice-options="contactExportChoiceOptions"
              :segment-options="segmentOptions"
              :show-export-delta-files-checkbox="showExportDeltaFilesCheckbox"
              :show-create-empty-files-checkbox="showCreateEmptyFilesCheckbox"
              :show-file-name-input="showFileNameInput"
              :file-name-example="fileNameExample"
              :default-export-config="defaultExportConfig"
              :action-export-contact-choice-options="actionExportContactChoiceOptions"
              :action-export-code-choice-options="actionExportCodeChoiceOptions"
              :action-code-options="actionCodeOptions"
              :source-options="sourceOptions"
              :campaign-options="campaignOptions"
              :creative-options="creativeOptions"
              @clone-configuration="$emit('clone-configuration', index)"
              @remove-configuration="removeExportConfiguration(index)"
              @update-value="(key, value) => $emit('update-configuration', index, key, value)"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <v-dialog v-model="deleteExportDialog" max-width="360px" @click:outside="deleteExportId = null">
      <DeleteConfirmationDialog
        v-if="deleteExportDialog"
        header="Delete configuration"
        subheader="Are you sure you want to delete this export configuration? All unsaved changes will be removed."
        @dismiss="
          deleteExportDialog = false;
          deleteExportId = null;
        "
        @delete="deleteExportConfig"
      />
    </v-dialog>
  </div>
</template>

<script>
import AutomatedExportForm from "@/views/Plugins/components/AutomatedExportForm";
import DeleteConfirmationDialog from "@/sharedComponents/DeleteConfirmationDialog";
import importExportMixin from "@/utils/import-export-mixin";
import OuterList from "@/sharedComponents/OuterList";

export default {
  name: "AutomatedExportConfigurations",
  components: {
    AutomatedExportForm,
    DeleteConfirmationDialog,
    OuterList,
  },
  mixins: [importExportMixin],
  props: {
    automatedExportConfigurations: {
      type: Array,
      default: () => [],
      required: true,
    },
    exportTypeOptions: {
      type: Array,
      default: () => [],
    },
    contactExportChoiceOptions: {
      type: Array,
      default: () => [],
    },
    segmentOptions: {
      type: Array,
      default: () => [],
    },
    actionExportContactChoiceOptions: {
      type: Array,
      default: () => [],
    },
    actionExportCodeChoiceOptions: {
      type: Array,
      default: () => [],
    },
    actionCodeOptions: {
      type: Array,
      default: () => [],
    },
    sourceOptions: {
      type: Array,
      default: () => [],
    },
    campaignOptions: {
      type: Array,
      default: () => [],
    },
    creativeOptions: {
      type: Object,
      default: () => {},
    },
    showExportDeltaFilesCheckbox: {
      type: Boolean,
      default: true,
    },
    showCreateEmptyFilesCheckbox: {
      type: Boolean,
      default: true,
    },
    defaultExportConfig: {
      type: Object,
      default: null,
    },
    showFileNameInput: {
      type: Boolean,
      default: true,
    },
    fileNameExample: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      deleteExportDialog: null,
      deleteExportId: null,
    };
  },
  methods: {
    async deleteExportConfig() {
      this.$emit('delete-configuration', this.deleteExportId);

      this.deleteExportDialog = null;
      this.deleteExportId = null;
    },
    updateExportValue(index, key, value) {
      this.$emit('update-configuration', index, key, value);
    },
    removeExportConfiguration(index) {
      this.deleteExportDialog = true;
      this.deleteExportId = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.section-header {
  margin-top: 30px;
  font-size: 1.25rem;
  font-weight: bold;
}
.single-configuration {
  .v-expansion-panel-header {
    background-color: #d4e6fb;
  }
  ::v-deep .v-expansion-panel-content__wrap {
    background-color: #f1f7fd;
  }
}
.grouped-configuration {
  .v-expansion-panel-header {
    background-color: #fac2d2;
  }
  ::v-deep .v-expansion-panel-content__wrap {
    background-color: #fdf2f6;
  }
}
</style>
