<template>
  <AutomatedPluginForm
    :form="form"
    @update-value="(field, val) => $emit('update-value', field, val)"
    @clone-configuration="$emit('clone-configuration')"
    @remove-configuration="$emit('remove-configuration')"
  >
    <template #extra-filesystem-details>
      <div>
        <v-tooltip right>
          <template #activator="{ on, attrs }">
            <div class="field__subheader filename-tooltip">
              Archive Directory
              <v-icon
                class="ml-1"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-information-outline
              </v-icon>
            </div>
          </template>
          <div class="d-flex flex-column">
            <span class="mb-1">Use the following placeholders to add dynamic values to the archive directory:</span>
            <span
              v-for="(tooltip, placeholder) in dynamicTimestampTooltips"
              :key="'archive-' + placeholder"
            >
              {{ placeholder }} - {{ tooltip.name }} ({{ tooltip.exampleValue }})
            </span>
          </div>
        </v-tooltip>
        <CustomTextInput
          :value="form.archiveDirectory"
          class="mb-2"
          :hint="'This directory is where the processed files get moved to after they are fully imported. Leave empty to not archive processed files.' + archiveHint"
          @input="val => $emit('update-value', 'archiveDirectory', val)"
        />
      </div>
      <div>
        <CustomDropdown
          :value="form.fileExtension"
          :items="fileExtensionOptions"
          header="File Extension to Import"
          item-value="id"
          item-text="text"
          required
          :readonly="fileExtensionOptions.length === 1"
          @input="val => $emit('update-value', 'fileExtension', val)"
        />
      </div>
    </template>
    <template #plugin-options>
      <div v-if="importTypeOptions.length > 0">
        <CustomDropdown
          :value="form.importType"
          :items="importTypeOptions"
          header="Import Type"
          item-value="id"
          item-text="text"
          required
          @change="handleImportTypeChange"
        />
      </div>
      <div v-if="isImportTypeContact">
        <CustomDropdown
          :items="segmentOptions"
          header="Add Contacts to Segment"
          item-value="id"
          item-text="text"
          :value="form.importOptions.segmentId"
          hint="Optionally, after the contact is created you can have them automatically added to the segment chosen."
          @input="val => $emit('update-value', 'importOptions.segmentId', val)"
        />
      </div>
    </template>
  </AutomatedPluginForm>
</template>

<script>
import importExportMixin from "@/utils/import-export-mixin.js";
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import AutomatedPluginForm from "@/views/Plugins/components/AutomatedPluginForm";

export default {
  name: "AutomatedImportForm",
  components: {
    CustomTextInput,
    CustomDropdown,
    AutomatedPluginForm,
  },
  mixins: [importExportMixin],
  props: {
    form: {
      type: Object,
      required: true,
    },
    importTypeOptions: {
      type: Array,
      default: () => [],
    },
    segmentOptions: {
      type: Array,
      default: () => [],
    },
    fileExtensionOptions: {
      type: Array,
      default: () => [
        {
          id: 'csv',
          text: 'Comma-Separated Values (.csv)',
        },
        {
          id: 'txt',
          text: 'Text File (.txt)',
        },
      ],
    }
  },
  computed: {
    isImportTypeContact() {
      return this.form.importType === 'contact';
    },
    isImportTypeAction() {
      return this.form.importType === 'action';
    },
    archiveHint() {
      if (!this.form?.archiveDirectory) {
        return '';
      }

      const replaced = this.replaceDynamicValues(this.form.archiveDirectory);

      if (this.form.archiveDirectory === replaced) {
        return '';
      }

      return ` Example Directory: "${replaced}"`;
    },
  },
  created() {
    if (!this.form?.fileExtension && this.fileExtensionOptions[0]?.id) {
      this.$emit('update-value', 'fileExtension', this.fileExtensionOptions[0]?.id);
    }
  },
  methods: {
    handleImportTypeChange(val) {
      this.$emit('update-value', 'importOptions', {});
      this.$emit('update-value', 'importType', val);
    },
  },
};
</script>

<style scoped>
.filename-tooltip {
  display: flex;
  align-items: center;
  width: fit-content;
  margin-bottom: -4px;
}

::v-deep .custom-checkbox {
  &.v-input--selection-controls {
    margin-top: 0;
  }

  .v-label {
    font-size: 13px;
  }
}
</style>
