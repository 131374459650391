<template>
  <div class="single-export-form-fields">
    <slot name="single-export-form-pre-extra-fields" />
    <slot name="plugin-filename">
      <div
        v-if="showFileNameInput"
        class="mb-2"
      >
        <v-tooltip right>
          <template #activator="{ on, attrs }">
            <div class="field__subheader filename-tooltip">
              <span class="field__subheader--required mr-1">*</span>
              Export File Name
              <v-icon
                class="ml-1"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-information-outline
              </v-icon>
            </div>
          </template>
          <div class="d-flex flex-column">
            <span class="mb-1">Use the following placeholders to add dynamic values to the file name:</span>
            <span
              v-for="(tooltip, placeholder) in dynamicTimestampTooltips"
              :key="'filename-' + placeholder"
            >
              {{ placeholder }} - {{ tooltip.name }} ({{ tooltip.exampleValue }})
            </span>
          </div>
        </v-tooltip>
        <CustomTextInput
          :value="config.fileName"
          required
          :hint="fileNameHint"
          @input="val => $emit('update-field', 'fileName', val)"
        />
      </div>
      <div
        v-else
        class="mb-2"
      >
        <div class="field__subheader mb-3">
          Export File Name
        </div>
        <div
          class="mb-3"
          style="font-size: 13px;"
        >
          The file name must be generated by the plugin. For this reason, this value cannot be configured here.<br>
          <span v-if="fileNameExample">
            {{ fileNameExample }}
          </span>
        </div>
      </div>
    </slot>
    <slot name="plugin-delta-files">
      <div v-if="showExportDeltaFilesCheckbox">
        <v-checkbox
          v-model="exportDeltaFiles"
          color="#2b84eb"
          label="Export Delta Files"
          class="custom-checkbox"
          @change="$emit('update-field', 'exportDeltaFiles', !!exportDeltaFiles);"
        />
        <div class="v-text-field__details" style="padding: 0 12px;">
          <div class="v-messages theme--light">
            <div class="v-messages__message">
              If delta files are requested, the initial export will contain all records. Any future exports will only contain records since the last time an export was created by this configuration.
            </div>
          </div>
        </div>
      </div>
      <div v-else class="mb-5">
        <div class="field__subheader filename-tooltip">
          Export Delta Files
        </div>
        <div
          class="my-3"
          style="font-size: 13px;"
        >
          This export configuration requires delta files to be exported.
        </div>
      </div>
    </slot>
    <slot name="plugin-create-empty-files">
      <div v-if="showCreateEmptyFilesCheckbox">
        <v-checkbox
          v-model="canCreateEmptyFile"
          color="#2b84eb"
          class="custom-checkbox"
          label="Create a file even if there is nothing to export"
          @change="$emit('update-field', 'canCreateEmptyFile', !!canCreateEmptyFile);"
        />
      </div>
    </slot>
    <slot name="plugin-options">
      <div v-if="exportTypeOptions.length > 0">
        <CustomDropdown
          :value="config.exportType"
          :items="exportTypeOptions"
          header="Export Type"
          item-value="id"
          item-text="text"
          required
          :readonly="config.isExisting || exportTypeOptions.length <= 1"
          @input="handleExportTypeChange"
        />
      </div>
      <div v-if="exportFileHeaders !== null">
        <template v-if="exportFileHeaders.length > 0">
          <div class="field__subheader d-flex">
            <span class="field__subheader--required"> * </span>
            Fields to Export
          </div>
          <v-row class="mb-3">
            <v-col cols="12">
              <v-checkbox
                v-model="checkAllFields"
                :label="checkAllFields ? 'Uncheck All' : 'Check All'"
                hide-details
                class="my-2 custom-checkbox"
                @change="handleCheckAll"
              />
            </v-col>
            <v-col
              v-for="header in exportFileHeaders"
              :key="header"
              sm="12"
              md="6"
              lg="4"
              class="py-0"
            >
              <v-checkbox
                v-model="selectedFileHeaders"
                :label="header"
                :value="header"
                hide-details
                class="custom-checkbox"
                @change="handleExportFileHeaderChange"
              />
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <v-alert
            type="error"
            class="mt-2"
            style="font-size: 12px;"
          >
            An unexpected error has occurred. Unable to display file headers for the selected export type.
          </v-alert>
        </template>
      </div>
      <div>
        <CustomDropdown
          v-if="!disableContactInput"
          :value="config.exportOptions.choice"
          :items="contactExportChoiceOptions"
          header="Contacts to Export"
          item-value="id"
          item-text="text"
          required
          :readonly="config.isExisting"
          @input="handleContactChoiceInput"
        />
        <CustomDropdown
          v-if="config.exportOptions.choice === 'segment'"
          :items="segmentOptions"
          header="Segment"
          item-value="id"
          item-text="text"
          required
          :readonly="config.isExisting"
          :value="config.exportOptions.segmentId"
          @input="val => $emit('update-field', 'exportOptions.segmentId', val)"
        />
        <v-alert
          v-if="isDynamicSegmentChosenForContactExport && exportDeltaFiles"
          outlined
          color="teal"
          text
        >
          Note: You are exporting delta files for a dynamic segment.
          Since contacts can go in and out of these segments based on the segment criteria, please be aware that it
          could cause the contact to show up in later files if they re-enter the segment.
        </v-alert>
        <CustomDropdown
          v-if="!disableContactInput"
          :items="excludeTestContactsItems"
          header="Exclude Test Contacts"
          item-value="value"
          item-text="label"
          required
          :value="config.exportOptions.excludeTestContacts"
          :readonly="config.isExisting"
          @input="val => $emit('update-field', 'exportOptions.excludeTestContacts', val)"
        />
      </div>
      <div v-if="isExportTypeAction">
        <CustomDropdown
          :value="config.exportOptions.actionChoice"
          :items="actionExportCodeChoiceOptions"
          header="Actions to Export"
          item-value="id"
          item-text="text"
          required
          :readonly="config.isExisting"
          @input="handleActionChoiceInput"
        />
        <CustomDropdown
          v-if="config.exportOptions.actionChoice === 'specific_action_codes'"
          :items="actionCodeValues"
          header="Specific Action Codes"
          item-value="id"
          item-text="text"
          required
          :value="config.exportOptions.actionCodes"
          :readonly="config.isExisting"
          multiple
          @input="val => $emit('update-field', 'exportOptions.actionCodes', val)"
        />
        <CustomDropdown
          v-if="config.exportType === 'email_action' && campaignValueOptions.length > 0"
          :items="campaignValueOptions"
          header="Specific Campaign"
          item-value="id"
          item-text="text"
          :value="config.exportOptions.campaign"
          :readonly="config.isExisting"
          multiple
          @input="handleSpecificCampaign"
        />
        <CustomDropdown
          v-if="exportCreativeOptions.length > 0"
          :items="exportCreativeOptions"
          header="Specific Creative"
          item-value="id"
          item-text="text"
          :value="config.exportOptions.creative"
          :readonly="config.isExisting"
          multiple
          @input="handleSpecificCreative"
        />
        <CustomDropdown
          :items="sourceOptions"
          header="Specific Action Sources"
          item-value="id"
          item-text="text"
          :value="config.exportOptions.source"
          :readonly="config.isExisting"
          multiple
          @input="handleSpecificSources"
        />
      </div>
      <slot name="single-export-form-extra-fields" />
    </slot>
  </div>
</template>

<script>
import importExportMixin from "@/utils/import-export-mixin.js";
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import CustomDropdown from "@/sharedComponents/CustomDropdown";

export default {
  name: "SingleAutomatedExportFormFields",
  components: {
    CustomTextInput,
    CustomDropdown,
  },
  mixins: [importExportMixin],
  props: {
    config: {
      type: Object,
      required: true,
    },
    exportTypeOptions: {
      type: Array,
      default: () => [],
    },
    contactExportChoiceOptions: {
      type: Array,
      default: () => [],
    },
    segmentOptions: {
      type: Array,
      default: () => [],
    },
    actionExportContactChoiceOptions: {
      type: Array,
      default: () => [],
    },
    actionExportCodeChoiceOptions: {
      type: Array,
      default: () => [],
    },
    actionCodeOptions: {
      type: Array,
      default: () => [],
    },
    sourceOptions: {
      type: Array,
      default: () => [],
    },
    campaignOptions: {
      type: Array,
      default: () => [],
    },
    creativeOptions: {
      type: Object,
      default: () => {},
    },
    showExportDeltaFilesCheckbox: {
      type: Boolean,
      default: true,
    },
    showCreateEmptyFilesCheckbox: {
      type: Boolean,
      default: true,
    },
    showFileNameInput: {
      type: Boolean,
      default: true,
    },
    fileNameExample: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      exportDeltaFiles: true,
      canCreateEmptyFile: null,
      exportTypeCache: {},
      selectedFileHeaders: [],
      checkAllFields: false,
      excludeTestContactsItems: [
        {
          label: "Yes",
          value: true,
          selected: true,
        },
        {
          label: "No",
          value: false,
          selected: false
        },
      ]
    };
  },
  computed: {
    actionCodeValues() {
      let tacticId = 0;
      if (this.config.exportType === 'email_action') {
        tacticId = 1;
      }
      if (this.config.exportType === 'direct_mail_action') {
        tacticId = 2;
      }
      if (this.config.exportType === 'phone_action') {
        tacticId = 4;
      }
      if (this.config.exportType === 'banner_action') {
        tacticId = 5;
      }
      if (this.config.exportType === 'web_action') {
        tacticId = 7;
      }
      if (this.config.exportType === 'event_action') {
        tacticId = 8;
      }
      if (this.config.exportType === 'form_action') {
        tacticId = 10;
      }
      if (this.config.exportType === 'alert_action') {
        tacticId = 11;
      }
      if (this.config.exportType === 'prescription_action') {
        tacticId = 12;
      }

      return this.actionCodeOptions.filter(item => item?.tacticId === tacticId).map(item => ({
        id: item.id,
        text: item.name,
      }));
    },
    creativeOptionValue() {
      if (!this.creativeOptions) {
        return null;
      }

      const allowedExportTypes = Object.keys(this.creativeOptions);
      if (!this.config.exportType || !allowedExportTypes.includes(this.config.exportType)) {
        return null;
      }

      return this.config?.exportOptions?.creative ?? null;
    },
    campaignValueOptions() {
      if (this.config.isExisting) {
        return this.campaignOptions;
      }

      return this.campaignOptions.filter(o => !o.text.includes('[Archived]'));
    },
    exportCreativeOptions() {
      if (!this.creativeOptions) {
        return [];
      }
      const allowedExportTypes = Object.keys(this.creativeOptions);
      if (!this.config.exportType || !allowedExportTypes.includes(this.config.exportType)) {
        return [];
      }

      return this.creativeOptions[this.config.exportType];
    },
    disableContactInput() {
      return ['dcc_contact', 'lahlouh_contact'].includes(this.config.exportType)
    },
    isExportTypeAction() {
      return [
        'email_action',
        'direct_mail_action',
        'phone_action',
        'banner_action',
        'web_action',
        'event_action',
        'form_action',
        'alert_action',
        'prescription_action',
      ].includes(this.config.exportType);
    },
    isDynamicSegmentChosenForContactExport() {
      if (this.config.exportOptions.choice !== 'segment') {
        return false;
      }

      const segment = this.segmentOptions.find((elem) => elem.id === this.config.exportOptions.segmentId);
      if (!segment) {
        return false;
      }

      return segment.type === '2';
    },
    fileNameHint() {
      return this.getFileNameHint(this.config?.fileName);
    },
    exportFileHeaders() {
      if (!this.config?.exportType || this.exportTypeCache[this.config?.exportType] === null) {
        return null;
      }

      return this.exportTypeCache[this.config?.exportType] ?? [];
    }
  },
  mounted() {
    this.exportDeltaFiles = this.config.exportDeltaFiles;
    this.canCreateEmptyFile = this.config.canCreateEmptyFile;

    if (this.config?.exportType) {
      this.handleExportTypeChange(this.config?.exportType, false);
    }

    if (this.config?.exportOptions?.fieldsToExport) {
      this.selectedFileHeaders = [...this.config?.exportOptions?.fieldsToExport];
    }
  },
  methods: {
    handleCheckAll(val) {
      this.checkAllFields = val;

      if (this.checkAllFields) {
        this.selectedFileHeaders = this.exportFileHeaders;
      } else {
        this.selectedFileHeaders = [];
      }

      this.handleExportFileHeaderChange();
    },
    handleSpecificCampaign(val) {
      const selected = val.filter(v => v !== null);
      this.$emit('update-field', 'exportOptions.campaign', selected.length > 0 ? selected : null);
    },
    handleSpecificCreative(val) {
      const selected = val.filter(v => v !== null);
      this.$emit('update-field', 'exportOptions.creative', selected.length > 0 ? selected : null);
    },
    handleSpecificSources(val) {
      const selected = val.filter(v => v !== null);
      this.$emit('update-field', 'exportOptions.source', selected.length > 0 ? selected : null);
    },
    handleExportFileHeaderChange() {
      const exportOptions = {...this.config?.exportOptions ?? {}};
      exportOptions.fieldsToExport = this.selectedFileHeaders;
      this.$emit('update-field', 'exportOptions', exportOptions);
    },
    handleExportTypeChange(val, clear = true) {
      if (!val) {
        return;
      }
      if (clear) {
        this.$emit('update-field', 'exportOptions', {});
        this.$emit('update-field', 'exportType', val);
      }

      if (!this.exportTypeCache[val]) {
        this.$set(this.exportTypeCache, val, null);

        this.$rest.exports.get_export_type(val).then((resp) => {
          this.$set(this.exportTypeCache, val, resp.data?.headers ?? []);
        });
      }
    },
    handleContactChoiceInput(val) {
      if (val === 'all') {
        this.$emit('update-field', 'exportOptions.segmentId', null);
      }
      this.$emit('update-field', 'exportOptions.choice', val);
    },
    handleActionChoiceInput(val) {
      if (val === 'all') {
        this.$emit('update-field', 'exportOptions.actionCodes', null);
      }
      this.$emit('update-field', 'exportOptions.actionChoice', val);
    },
  },
};
</script>

<style scoped lang="scss">
.filename-tooltip {
  display: flex;
  align-items: center;
  width: fit-content;
  margin-bottom: -4px;
}

::v-deep .custom-checkbox {
  &.v-input--selection-controls {
    margin-top: 0;
  }

  .v-label {
    font-size: 13px;
  }
}
</style>
