import dayjs from "dayjs";
import cloneDeep from "lodash/cloneDeep";
import {v4 as uuidv4} from "uuid";

export default {
  data() {
    return {
      importConfigurations: [],
      exportConfigurations: [],
      exportConfigurationTypes: [
        { label: "Add Single Export", value: "single" },
        { label: "Add Grouped Export", value: "grouped" },
      ],
    };
  },
  computed: {
    filesystemDirectoryHint() {
      return '';
    },
    dynamicTimestampTooltips() {
      const curr = new Date();
      const now = curr.toISOString();
      const year = new Date(curr.getFullYear(), 0, 1);
      const days = Math.floor((curr - year) / (24 * 60 * 60 * 1000));
      const week = Math.ceil(( curr.getDay() + 1 + days) / 7);

      const lastMonth = dayjs().subtract(1, 'month');
      const lastMonthString = lastMonth.toISOString();
      const lastMonthYear = new Date(lastMonth.year(), 0, 1);
      const lastMonthDays = Math.floor((lastMonth - lastMonthYear) / (24 * 60 * 60 * 1000));
      const lastMonthWeek = Math.ceil(( lastMonth.day() + 1 + lastMonthDays) / 7);

      const lastWeek = dayjs().subtract(1, 'week');
      const lastWeekString = lastWeek.toISOString();
      const lastWeekYear = new Date(lastWeek.year(), 0, 1);
      const lastWeekDays = Math.floor((lastWeek - lastWeekYear) / (24 * 60 * 60 * 1000));
      const lastWeekWeek = Math.ceil(( lastWeek.day() + 1 + lastWeekDays) / 7);

      return {
        '{{YYYY}}': {
          name: 'Full Year',
          exampleValue: now.slice(0, 4),
        },
        '{{YY}}': {
          name: '2 digit Year',
          exampleValue: now.slice(2, 4),
        },
        '{{MM}}': {
          name: 'Month',
          exampleValue: now.slice(5, 7),
        },
        '{{DD}}': {
          name: 'Day',
          exampleValue: now.slice(8, 10),
        },
        '{{hh}}': {
          name: 'Hour',
          exampleValue: now.slice(11, 13),
        },
        '{{mm}}': {
          name: 'Minute',
          exampleValue: now.slice(14, 16),
        },
        '{{ss}}': {
          name: 'Second',
          exampleValue: now.slice(17, 19),
        },
        '{{week}}': {
          name: 'Week number of Year',
          exampleValue: week,
        },
        '{{1_month_ago_YYYY}}': {
          name: 'Full Year for 1 month ago',
          exampleValue: lastMonthString.slice(0, 4),
        },
        '{{1_month_ago_YY}}': {
          name: '2 digit Year for 1 month ago',
          exampleValue: lastMonthString.slice(2, 4),
        },
        '{{1_month_ago_MM}}': {
          name: 'Month for 1 month ago',
          exampleValue: lastMonthString.slice(5, 7),
        },
        '{{1_month_ago_DD}}': {
          name: 'Day for 1 month ago',
          exampleValue: lastMonthString.slice(8, 10),
        },
        '{{1_month_ago_week}}': {
          name: 'Week number for 1 month ago',
          exampleValue: lastMonthWeek,
        },
        '{{1_week_ago_YYYY}}': {
          name: 'Full Year for 1 week ago',
          exampleValue: lastWeekString.slice(0, 4),
        },
        '{{1_week_ago_YY}}': {
          name: '2 digit Year for 1 week ago',
          exampleValue: lastWeekString.slice(2, 4),
        },
        '{{1_week_ago_MM}}': {
          name: 'Month for 1 week ago',
          exampleValue: lastWeekString.slice(5, 7),
        },
        '{{1_week_ago_DD}}': {
          name: 'Day for 1 week ago',
          exampleValue: lastWeekString.slice(8, 10),
        },
        '{{1_week_ago_week}}': {
          name: 'Week number for 1 week ago',
          exampleValue: lastWeekWeek,
        },
      };
    },
  },
  methods: {
    getFileNameHint(fileName) {
      const defaultHint = 'This value will be used as the file name of the exported file. Don\'t forget to add a file ending (ie: .csv, .txt).';
      if (!fileName) {
        return defaultHint;
      }

      const replaced = this.replaceDynamicValues(fileName);

      if (fileName === replaced) {
        return defaultHint;
      }

      return `${defaultHint} Example File Name: "${replaced}"`;
    },
    getConfigurationClasses(form) {
      const classes = {
        'mt-4': true,
      };

      classes[form.type + '-configuration'] = true;

      return classes;
    },
    replaceDynamicValues(originalString) {
      let string = '' + originalString;

      for (let placeholder in this.dynamicTimestampTooltips) {
        let exampleValue = this.dynamicTimestampTooltips[placeholder]?.exampleValue;

        string = string.replaceAll(placeholder, exampleValue);
      }

      return string;
    },
    setupExportConfigurations(configurations, credentials) {
      const exportConfigurations = configurations.map(config => this.configMapFunc(config, credentials));
      exportConfigurations.forEach(config => {
        this.addExportConfiguration(config);
      });
    },
    setupImportConfigurations(configurations, credentials) {
      const importConfigurations = configurations.map(config => this.configMapFunc(config, credentials));
      importConfigurations.forEach(config => {
        this.addImportConfiguration(config);
      });
    },
    configMapFunc(config, credentials) {
      const configCredentials = credentials[config.key] ?? null;

      if (config?.frequency?.time) {
        const [hour, minute] = config.frequency.time.split(":");
        if (+hour >= 12) {
          config.frequency.ampm = "PM"
          config.frequency.hour = +hour > 12 ? +hour - 12 : 12;
        } else {
          config.frequency.ampm = "AM"
          config.frequency.hour = (+hour === 0) ? 12 : hour;
        }
        config.frequency.minute = minute;

        // Remove this config in case the frequency changes
        delete config.frequency.nextRunDate;
      }

      return {
        ...config,
        credentials: configCredentials,
      };
    },
    addImportConfiguration(configuration) {
      this.importConfigurations.push(configuration);
    },
    async deleteImportConfiguration(index) {
      this.importConfigurations.splice(index, 1);

      if (this.installed) {
        await this.installPlugin(this.importConfigurations, true);
      }

      this.$store.commit('snackbar/showMessage', {
        content: 'Import Configuration Deleted',
        color: 'success',
      });
    },
    updateImportValue(index, key, value) {
      const configuration = this.importConfigurations[index];

      if (key.includes('.')) {
        const [parentKey, childKey] = key.split('.');

        configuration[parentKey][childKey] = value;
        const update = {};
        update[childKey] = value;
        this.$set(configuration, parentKey, {
          ...configuration[parentKey],
          ...update
        });
      } else {
        configuration[key] = value;
        this.$set(configuration, key, value);
      }
    },
    formatConfig(config) {
      const { credentials, ...configuration } = cloneDeep(config);

      if (configuration.frequency.ampm) {
        const hour =
          configuration.frequency.ampm === 'PM' && configuration.frequency.hour < 12
            ? configuration.frequency.hour + 12
            : (
              configuration.frequency.ampm === 'AM' && configuration.frequency.hour === 12
                ? 0
                : configuration.frequency.hour
            );

        configuration.frequency.time = `${hour.toString().padStart(2, '0')}:${
            configuration.frequency.minute
        }:00`;
      }

      // remove extra properties
      delete configuration.frequency.hour;
      delete configuration.frequency.minute;
      delete configuration.frequency.ampm;

      if (configuration.frequency.type === 'daily') {
        delete configuration.frequency.day;
      }

      if (configuration.connectionType === 'FTP'
          || configuration.connectionType === 'FTPS'
          || configuration.connectionType === 'SFTP'
      ) {
        delete credentials.privateKey;
        delete credentials.aws_region;
        delete credentials.aws_access_id;
        delete credentials.aws_secret_key;
        delete credentials.aws_bucket;
      } else if (configuration.connectionType === 'SFTP_WITH_KEY') {
        delete credentials.password;
        delete credentials.aws_region;
        delete credentials.aws_access_id;
        delete credentials.aws_secret_key;
        delete credentials.aws_bucket;
      } else if (configuration.connectionType === 'AMAZON_S3') {
        delete credentials.username;
        delete credentials.password;
        delete credentials.port;
        delete credentials.host;
        delete credentials.privateKey;
      }

      delete configuration.isShowing;

      if (configuration?.type === 'grouped') {
        configuration?.exports?.forEach(exp => {
          delete exp.isShowing;
        });
      }

      return {credentials, configuration};
    },
    getFormattedPluginConfiguration(expectedImportConfig) {
      const pluginConfiguration = {
        importConfigurations: [],
        credentials: {},
      };

      for (const config of expectedImportConfig) {
        const result = this.formatConfig(config);

        pluginConfiguration.importConfigurations.push(result.configuration);
        pluginConfiguration.credentials[result.configuration.key] = result.credentials;
      }

      return pluginConfiguration;
    },
    getFormattedImportAndExportPluginConfiguration(expectedExportConfig, expectedImportConfig) {
      const pluginConfiguration = {
        exportConfigurations: [],
        importConfigurations: [],
        credentials: {},
      };

      for (const config of (expectedExportConfig || this.exportConfigurations)) {
        const result = this.formatConfig(config);

        pluginConfiguration.exportConfigurations.push(result.configuration);
        pluginConfiguration.credentials[result.configuration.key] = result.credentials;
      }
      for (const config of (expectedImportConfig || this.importConfigurations)) {
        const result = this.formatConfig(config);

        pluginConfiguration.importConfigurations.push(result.configuration);
        pluginConfiguration.credentials[result.configuration.key] = result.credentials;
      }

      return pluginConfiguration;
    },
    addExportConfiguration(configuration) {
      this.exportConfigurations.push(configuration);
    },
    generateExportConfiguration(existingConfig, isShowing = null) {
      const config = {
        type: existingConfig?.type || 'single',
        createControlFile: !!(existingConfig?.createControlFile ?? false),
        controlFileName: existingConfig?.controlFileName || null,
        exports: existingConfig?.exports || [],
        connectionType: existingConfig?.connectionType || null,
        directory: existingConfig?.directory || '/',
        credentials: {
          host: existingConfig?.credentials?.host || null,
          username: existingConfig?.credentials?.username || null,
          password: existingConfig?.credentials?.password || null,
          port: existingConfig?.credentials?.port || null,
          privateKey: existingConfig?.credentials?.privateKey || null,
          aws_region: existingConfig?.credentials?.aws_region || null,
          aws_access_id: existingConfig?.credentials?.aws_access_id || null,
          aws_secret_key: existingConfig?.credentials?.aws_secret_key || null,
          aws_bucket: existingConfig?.credentials?.aws_bucket || null,
        },
        frequency: existingConfig?.frequency ? {...existingConfig?.frequency} : {
          type: null,
          ampm: (new Date()).getHours() >= 12 ? 'PM' : 'AM',
          hour: (new Date()).getHours(),
          minute: '00',
          timezone: 'America/New_York',
          day: [],
        },
        exportType: existingConfig?.exportType || null,
        exportOptions: existingConfig?.exportOptions ? {...existingConfig?.exportOptions} : { choice: null },
        name: existingConfig?.name || '',
        fileName: existingConfig?.fileName || '',
        notificationRecipients: existingConfig?.notificationRecipients || '',
        key: existingConfig?.key || uuidv4(),
        isShowing,
        history: existingConfig?.history ? [...existingConfig?.history] : [],
        exportDeltaFiles: existingConfig?.exportDeltaFiles ?? true,
        lastExportDate: existingConfig?.lastExportDate || null,
        canCreateEmptyFile: existingConfig?.canCreateEmptyFile ?? false,
        isExisting: !!existingConfig?.key,
      };

      if (config.exports.length) {
        config.exports = config.exports.map(exp => ({
          ...exp,
          isExisting: !!exp?.key,
          isShowing,
        }));
      }

      return config;
    },
    async deleteExportConfiguration(index) {
      this.exportConfigurations.splice(index, 1);

      if (this.installed) {
        await this.installPlugin(this.exportConfigurations, true);
      }

      this.$store.commit('snackbar/showMessage', {
        content: 'Export Configuration Deleted',
        color: 'success',
      });
    },
    updateExportValue(index, key, value) {
      const configuration = this.exportConfigurations[index] ?? null;
      if (!configuration) {
        return;
      }

      if (key.includes('.')) {
        const [parentKey, childKey] = key.split('.');

        configuration[parentKey][childKey] = value;
        const update = {};
        update[childKey] = value;
        this.$set(configuration, parentKey, {
          ...configuration[parentKey],
          ...update
        });
      } else {
        this.$set(configuration, key, value);
      }

      this.$set(this.exportConfigurations, index, {...configuration});
    },
  }
};
