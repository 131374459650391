<template>
  <CustomDropdown
    v-model="valueProp"
    :readonly="readonly"
    :header="header"
    :header-class="headerClass"
    :required="required"
    :items="timezones"
    item-text="text"
    item-value="value"
    @change="(newValue) => $emit('change', newValue)"
    @input="(newValue) => $emit('input', newValue)"
  />
</template>

<script>
import CustomDropdown from "@/sharedComponents/CustomDropdown";

export default {
  name: "TimezoneDropdown",
  components: { CustomDropdown },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    header: {
      type: String,
      default: 'Timezone',
    },
    readonly: {
      default: false,
      type: Boolean,
    },
    headerClass: {
      type: String,
      default: '',
    },
    required: {
      default: () => false,
      type: Boolean,
    },
    value: {
      default: () => null,
    },
  },
  data: () => ({
    timezones: [
      { value: 'America/New_York', text: 'Eastern Standard Time' },
      { value: 'America/Chicago', text: 'Central Standard Time' },
      { value: 'America/Denver', text: 'Mountain Time' },
      { value: 'America/Los_Angeles', text: 'Pacific Time' },
      { value: 'America/Anchorage', text: 'Alaska Time' },
      { value: 'America/Adak', text: 'Hawaii-Aleutian Time' },
    ],
  }),
  computed: {
    valueProp: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
};
</script>

