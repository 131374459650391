<template>
  <div class="mt-4">
    <validation-observer ref="inputRef">
      <div>
        <CustomTextInput
          :value="form.name"
          class="mb-2"
          header="Name"
          required
          hint="This name will be used to identify this configuration. You may see it in notification emails. We recommend setting a value that can easily identify this configuration."
          @input="val => $emit('update-value', 'name', val)"
        />
      </div>

      <div>
        <CustomDropdown
          :value="form.connectionType"
          :items="connectionTypes"
          header="Connection Type"
          item-value="id"
          item-text="text"
          required
          @input="val => $emit('update-value', 'connectionType', val)"
        />
      </div>

      <div v-if="canConfigureSettings">
        <div v-if="canSeeNormalSettings">
          <CustomTextInput
            :value="form.credentials.host"
            header="Host"
            required
            @input="val => $emit('update-value', 'credentials.host', val)"
          />
          <CustomTextInput
            :value="form.credentials.port"
            header="Port"
            required
            @input="val => $emit('update-value', 'credentials.port', val)"
          />
          <CustomTextInput
            :value="form.credentials.username"
            header="Username"
            required
            @input="val => $emit('update-value', 'credentials.username', val)"
          />
          <CustomTextInput
            v-if="!canSeeSshKeySetting"
            type="password"
            :value="form.credentials.password"
            header="Password"
            :required="!canSeeSshKeySetting"
            @input="val => $emit('update-value', 'credentials.password', val)"
          />
        </div>
        <div v-if="canSeeSshKeySetting">
          <CustomFileUpload
            :value="form.credentials.privateKey"
            placeholder="SSH Key"
            required
            :hint="privateKeyHint"
            @input="val => $emit('update-value', 'credentials.privateKey', val)"
          />
        </div>
        <div v-if="canSeeAwsSettings">
          <CustomDropdown
            :value="form.credentials.aws_region"
            :items="awsRegions"
            header="Region"
            item-value="id"
            item-text="text"
            required
            @input="val => $emit('update-value', 'credentials.aws_region', val)"
          />
          <CustomTextInput
            :value="form.credentials.aws_access_id"
            header="AWS Access ID"
            required
            @input="val => $emit('update-value', 'credentials.aws_access_id', val)"
          />
          <CustomTextInput
            type="password"
            :value="form.credentials.aws_secret_key"
            header="AWS Secret Key"
            required
            @input="val => $emit('update-value', 'credentials.aws_secret_key', val)"
          />
          <CustomTextInput
            :value="form.credentials.aws_bucket"
            header="S3 Bucket Name"
            required
            @input="val => $emit('update-value', 'credentials.aws_bucket', val)"
          />
        </div>
      </div>
      <div v-if="form.connectionType">
        <v-btn
          height="34px"
          class="elevation-0 custom-button my-6"
          @click="testConnection"
        >
          Test Connection {{ isConnectionValid ? ' - Successful!' : '' }}
        </v-btn>
      </div>
      <div>
        <v-tooltip
          right
        >
          <template #activator="{ on, attrs }">
            <div
              class="field__subheader filename-tooltip"
            >
              <span
                class="field__subheader--required mr-1"
              >
                *
              </span>
              Filesystem Directory
              <v-icon
                class="ml-1"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-information-outline
              </v-icon>
            </div>
          </template>
          <div class="d-flex flex-column">
            <span class="mb-1">Use the following placeholders to add dynamic values to the directory:</span>
            <span
              v-for="(tooltip, placeholder) in dynamicTimestampTooltips"
              :key="'directory-' + placeholder"
            >
              {{ placeholder }} - {{ tooltip.name }} ({{ tooltip.exampleValue }})
            </span>
          </div>
        </v-tooltip>
        <CustomTextInput
          :value="form.directory"
          :hint="filesystemDirectoryHint"
          required
          @input="val => $emit('update-value', 'directory', val)"
        />
      </div>
      <slot name="extra-filesystem-details" />
      <div>
        <CustomDropdown
          :value="form.frequency.type"
          :items="frequencyOptions"
          header="Frequency"
          item-value="id"
          item-text="text"
          required
          @input="val => $emit('update-value', 'frequency.type', val)"
        />
      </div>
      <div v-if="form.frequency.type === 'monthly'">
        <CustomDropdown
          :items="monthlyDayOptions"
          header="Day(s) of the month"
          class="flex-grow-1"
          item-value="id"
          item-text="text"
          multiple
          autocomplete
          :value="form.frequency.day"
          required
          hint="The 29th, 30th and 31st are not possible values since some months will not contain those days."
          @input="handleFrequencyMonthlyChange"
        />
      </div>
      <div v-if="form.frequency.type === 'weekly'">
        <div
          v-for="day in ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']"
          :key="day"
          class="d-inline-block"
        >
          <v-checkbox
            v-model="weeklyFrequencyDays"
            :value="day"
            :label="day"
            :disabled="weeklyFrequencyDays.length === 6 && !weeklyFrequencyDays.includes(day)"
            class="mr-2"
            required
            @change="handleFrequencyWeekDaysChange"
          />
        </div>
      </div>
      <div class="d-flex">
        <CustomDropdown
          :items="hourOptions"
          header="Hour"
          class="flex-grow-1"
          item-value="id"
          item-text="text"
          :value="time.hour"
          required
          @input="val => $emit('update-value', 'frequency.hour', val)"
        />
        <CustomDropdown
          :items="minuteOptions"
          header="Minutes"
          class="ml-2 flex-grow-1"
          item-value="id"
          item-text="text"
          :value="time.minute"
          required
          @input="val => $emit('update-value', 'frequency.minute', val)"
        />
        <CustomDropdown
          :items="amPmOptions"
          header="AM/PM"
          class="ml-2 flex-grow-1"
          item-value="id"
          item-text="text"
          :value="form.frequency.ampm"
          required
          @input="val => $emit('update-value', 'frequency.ampm', val)"
        />
        <TimezoneDropdown 
          class="ml-2 timezone-dropbox"
          :value="form.frequency.timezone || 'America/New_York'"
          required
          @change="$emit('update-value', 'frequency.timezone', $event)"
        />
      </div>
      <div>
        <CustomTextInput
          class="mb-3"
          :value="form.notificationRecipients"
          header="Notification Recipients"
          hint="A comma separated list of email addresses that should receive a notification once the process completes."
          @input="val => $emit('update-value', 'notificationRecipients', val)"
        />
      </div>
      <slot name="plugin-options" />
    </validation-observer>
    <v-row class="mt-4">
      <v-col cols="12" class="d-flex justify-end">
        <v-btn
          class="custom-button px-13 mr-4"
          height="34px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          @click="$emit('clone-configuration')"
        >
          Clone
        </v-btn>
        <v-btn
          class="custom-button custom-button--red px-13"
          height="34px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          @click="$emit('remove-configuration')"
        >
          Delete
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import datesMixin from "@/utils/datesMixin.js";
import importExportMixin from "@/utils/import-export-mixin.js";
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import CustomFileUpload from "@/sharedComponents/CustomFileUpload";
import TimezoneDropdown from "@/sharedComponents/TimezoneDropdown";

export default {
  name: "AutomatedPluginForm",
  components: { 
    CustomFileUpload,
    CustomDropdown,
    CustomTextInput,
    TimezoneDropdown,
  },
  mixins: [datesMixin,importExportMixin],
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isConnectionValid: false,
      weeklyFrequencyDays: [],
      connectionTypes: [
        { id: 'FTP', text: 'FTP' },
        { id: 'FTPS', text: 'FTPS' },
        { id: 'SFTP', text: 'SFTP' },
        { id: 'SFTP_WITH_KEY', text: 'SFTP w/ SSH Key' },
        { id: 'AMAZON_S3', text: 'Amazon S3' },
        { id: 'SHAREFILE', text: 'Pulse Health Sharefile' },
      ],
      // https://docs.aws.amazon.com/general/latest/gr/s3.html
      // filtered out the GOV items
      awsRegions: [
        { id: 'us-east-2', text: 'us-east-2 (Ohio)' },
        { id: 'us-east-1', text: 'us-east-1 (N. Virginia)' },
        { id: 'us-west-1', text: 'us-west-1 (N. California)' },
        { id: 'us-west-2', text: 'us-west-2 (Oregon)' },
        { id: 'af-south-1', text: 'af-south-1 (Cape Town)' },
        { id: 'ap-east-1', text: 'ap-east-1 (Hong Kong)' },
        { id: 'ap-south-1', text: 'ap-south-1 (Mumbai)' },
        { id: 'ap-northeast-3', text: 'ap-northeast-3 (Osaka)' },
        { id: 'ap-northeast-2', text: 'ap-northeast-2 (Seoul)' },
        { id: 'ap-southeast-1', text: 'ap-southeast-1 (Singapore)' },
        { id: 'ap-southeast-2', text: 'ap-southeast-2 (Sydney)' },
        { id: 'ap-northeast-1', text: 'ap-northeast-1 (Tokyo)' },
        { id: 'ca-central-1', text: 'ca-central-1 (Canada)' },
        { id: 'cn-north-1', text: 'cn-north-1 (Beijing)' },
        { id: 'cn-northwest-1', text: 'cn-northwest-1 (Ningxia)' },
        { id: 'eu-central-1', text: 'eu-central-1 (Frankfurt)' },
        { id: 'eu-west-1', text: 'eu-west-1 (Ireland)' },
        { id: 'eu-west-2', text: 'eu-west-2 (London)' },
        { id: 'eu-south-1', text: 'eu-south-1 (Milan)' },
        { id: 'eu-west-3', text: 'eu-west-3 (Paris)' },
        { id: 'sa-east-1', text: 'sa-east-1 (São Paulo)' },
        { id: 'me-south-1', text: 'me-south-1 (Bahrain)' },
      ],
      frequencyOptions: [
        { id: 'monthly', text: 'Specific Day(s) of Month' },
        { id: 'weekly', text: 'Specific Day(s) of Week' },
        { id: 'daily', text: 'Daily' },
      ],
      hourOptions: [
        { id: 1, text: 1 },
        { id: 2, text: 2 },
        { id: 3, text: 3 },
        { id: 4, text: 4 },
        { id: 5, text: 5 },
        { id: 6, text: 6 },
        { id: 7, text: 7 },
        { id: 8, text: 8 },
        { id: 9, text: 9 },
        { id: 10, text: 10 },
        { id: 11, text: 11 },
        { id: 12, text: 12 },
      ],
      minuteOptions: [
        { id: '00', text: '00' },
        { id: '15', text: '15' },
        { id: '30', text: '30' },
        { id: '45', text: '45' },
      ],
      amPmOptions: [
        { id: 'AM', text: 'AM' },
        { id: 'PM', text: 'PM' },
      ],
      monthlyDayOptions: [
        1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28
      ].map(i => ({id: i, text: i})),
    }
  },
  computed: {
    privateKeyHint() {
      if (!this.canSeeSshKeySetting || !this.form?.credentials?.privateKey?.fileName) {
        return '';
      }

      return 'This configuration is using the private key file named: ' + this.form.credentials.privateKey.fileName ?? 'N/A';
    },
    time() {
      if (!this.form?.frequency?.time) {
        let hour = +(new Date().getHours());

        if (hour > 12) hour -= 12;
        if (hour === 0) hour = 12;

        return { hour, minute: '00' };
      }

      let [hour, minute] = this.form.frequency.time.split(':');

      hour = +hour;
      if (hour > 12) hour -= 12;
      if (hour === 0) hour = 12;

      return {
        hour,
        minute,
      };
    },
    filesystemDirectoryHint() {
      const replaced = this.replaceDynamicValues(this.form.directory);

      if (this.form.directory === replaced) {
        return '';
      }

      return `Example Directory: "${replaced}"`;
    },
    canSeeNormalSettings() {
      return ['FTP', 'FTPS', 'SFTP', 'SFTP_WITH_KEY'].includes(this.form.connectionType);
    },
    canSeeSshKeySetting() {
      return this.form.connectionType === 'SFTP_WITH_KEY';
    },
    canSeeAwsSettings() {
      return this.form.connectionType === 'AMAZON_S3';
    },
    canConfigureSettings() {
      return !['SHAREFILE'].includes(this.form.connectionType);
    },
    events() {
      return this.form?.frequency?.day?.map(event => ({
        name: "",
        start: event,
        end: event,
      }))
    },
  },
  created() {
    this.weeklyFrequencyDays = this.form.frequency.day ?? [];
  },
  methods: {
    handleFrequencyWeekDaysChange() {
      this.$emit('update-value', 'frequency.day', this.weeklyFrequencyDays)
    },
    handleFrequencyMonthlyChange(val) {
      this.$emit('update-value', 'frequency.day', val.sort((a, b) => a - b));
    },
    async testConnection() {
      this.isConnectionValid = false;

      const credentials = { ...this.form.credentials };

      if (this.form.connectionType === 'FTP'
        || this.form.connectionType === 'FTPS'
        || this.form.connectionType === 'SFTP'
      ) {
        delete credentials.privateKey;
        delete credentials.aws_region;
        delete credentials.aws_access_id;
        delete credentials.aws_secret_key;
        delete credentials.aws_bucket;
      } else if (this.form.connectionType === 'SFTP_WITH_KEY') {
        delete credentials.password;
        delete credentials.aws_region;
        delete credentials.aws_access_id;
        delete credentials.aws_secret_key;
        delete credentials.aws_bucket;
      } else if (this.form.connectionType === 'AMAZON_S3') {
        delete credentials.username;
        delete credentials.password;
        delete credentials.port;
        delete credentials.host;
        delete credentials.privateKey;
      }

      await this.$rest.remote_connection.test({
        connectionType: this.form.connectionType,
        credentials: credentials,
        directory: this.form.directory,
      });
      // @TODO - Use content in API response to help the user find the directory

      this.isConnectionValid = true;
    },
  },
};
</script>

<style scoped>
.timezone-dropbox {
  width: 320px;
}
.filename-tooltip {
  display: flex;
  align-items: center;
  width: fit-content;
  margin-bottom: -4px;
}
</style>
