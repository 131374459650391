<template>
  <v-card class="custom-field-popup-card">
    <v-card-title class="px-5 pt-4 pb-4">
      <div>{{ header }}</div>
    </v-card-title>
    <v-card-text class="pa-5 pt-2">
      <div class="notification-menu__header" style="color: #66788e;">
        <slot name="subheader">
          {{ subheader }}
        </slot>
        <slot name="extra" />
      </div>
    </v-card-text>

    <v-card-actions
      class="d-flex justify-end px-5 pb-3 pt-3"
    >
      <div
        :class="cancel_button_class + ' elevation-0 px-7'"
        @click="$emit('dismiss')"
      >
        {{ cancel_button_name }}
      </div>
      <v-btn
        :class="action_button_class + ' px-6'"
        @click="$emit('delete')"
      >
        {{ action_button_name }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

export default {
  name: "DeleteConfirmationDialog",
  components: {},
  props: {
    header: {
      default: () => "Delete Item",
      type: String,
    },
    subheader: {
      default: () => "Do you really want to delete item?",
      type: String,
    },
    action_button_name: {
      default: () => "Delete",
      type: String,
    },
    cancel_button_name: {
      default: () => "Cancel",
      type: String,
    },
    action_button_class: {
      default: 'simple-text-button button--red',
      type: String,
    },
    cancel_button_class: {
      default: 'simple-text-button button--gray',
      type: String,
    },
  },
  data() {
    return {
      script: {
        name: null,
        URL: null,
        tracking_code: null,
      },
    };
  },
  computed: {},
  created() {},
  methods: {
    add() {
      this.$emit("add", this.script);
      this.$emit("dismiss");
    },
  },
};
</script>

